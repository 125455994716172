import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO/SEO"

import Hero from "../sections/gallery/Hero/Hero"

import PhotoGrid from "../sections/gallery/PhotoGrid/PhotoGrid"

import Content from "../sections/common/Content/Content"
import FancyNav from "../sections/common/FancyNav/FancyNav"

const GalleryPage = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicGallery.data

  return (
    <>
      <SEO
        title={pageData.meta_title.text}
        description={pageData.meta_description.text}
      />

      <section>
        <Hero />
      </section>

      <section>
        <PhotoGrid />
      </section>

      <section>
        <Content />
      </section>

      <section className="pb-12 md:pb-14 lg:pb-16">
        <FancyNav />
      </section>
    </>
  )
}

export default GalleryPage

export const query = graphql`
  query {
    prismicGallery {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`
