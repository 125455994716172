import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from "../../../components/Container/Container"

const PhotoGrid = () => {
  const { prismicGallery } = useStaticQuery(graphql`
    query {
      prismicGallery {
        data {
          photo_group {
            photo {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

  const galleryData = prismicGallery.data
  const gridItems = galleryData.photo_group

  return (
    <>
      <Container className="!pt-5">
        <div className="grid gap-6 md:grid-cols-2 md:gap-8 lg:gap-10">
          {gridItems.map(({ photo }, index) => {
            const photoImage = getImage(photo)
            return (
              <div key={index}>
                <GatsbyImage image={photoImage} alt={`Photo ${index}}`} />
              </div>
            )
          })}
        </div>
      </Container>
    </>
  )
}

export default PhotoGrid
